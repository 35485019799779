import React, { useContext } from "react";
import css from "styled-jsx/css";
import { Breadcrumbs } from "nextjs-breadcrumbs";
import { ThemeContext } from "../lib/contexts";
import stylingConfig from "../styling/config";

const styles = css`
  .page {
    max-width: 1150px;
    margin: auto;
    background-color: ${stylingConfig.colours.paleGreyTwo};
  }
  .page.analytics {
    max-width: 1440px;
    background-color: ${stylingConfig.colours.bluishBlack};
  }
  .content {
    margin: 0 20px;
  }
  .analytics .content {
    margin: 0 20px;
  }

  @media screen and (max-width: ${stylingConfig.breakpoints.xl}) {
    .analytics .content {
      margin: 0 20px;
    }
  }
`;

const breadcrumbsStyles = css.global`
  ol.breadcrumb {
    list-style: none;
    display: flex;
    align-items: center;
  }
  ol.breadcrumb li:not(:last-child)::after {
    content: "/";
    margin-right: 5px;
  }
  ol.breadcrumb li a {
    margin-right: 5px;
  }
`;

const Page = ({
  children,
  showBreadcrumbs = false
}: {
  children: React.ReactNode;
  showBreadcrumbs?: boolean;
}) => {
  const theme = useContext(ThemeContext);
  const breadcrumbs = Breadcrumbs();
  return (
    <div className={`page ${theme}`}>
      {showBreadcrumbs && breadcrumbs}
      <div className="content">{children}</div>
      <style jsx>{styles}</style>
      <style jsx>{breadcrumbsStyles}</style>
    </div>
  );
};

export default Page;
