import React from "react";
import css from "styled-jsx/css";
import Link from "next/link";
import stylingConfig from "../styling/config";

const styles = css`
  .tile__admin {
    cursor: pointer;
    height: 242px;
    width: 260px;
    margin: 9px;
    border-radius: 6px;
    background-color: ${stylingConfig.colours.koda};
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: WaggelNunito, Helvetica, Arial, sans-serif;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 2px solid ${stylingConfig.colours.koda};
    transition: all 0.3s;
    text-align: center;
  }

  .tile__admin:hover {
    border: 2px solid ${stylingConfig.colours.waggelBlue};
  }

  .tile__admin--imgwrapper {
    height: 142px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tile__admin--img {
    max-height: 75px;
  }

  .tile__admin--header {
    margin: 0px 20px 10px 20px;
    font-weight: 700;
    font-size: 17px;
    color: ${stylingConfig.colours.steel};
  }

  .tile__admin--text {
    margin: 0 20px;
    font-size: 14px;
    color: ${stylingConfig.colours.steel};
  }
`;

const Tile = ({
  icon = "",
  iconAlt = "",
  header = "",
  text = "",
  href = "/"
}: {
  icon?: string;
  iconAlt?: string;
  header?: string;
  text?: string;
  href?: string;
}) => {
  return (
    <div>
      <Link href={href}>
        <a style={{ textDecoration: "none" }}>
          <button type="button" className="tile__admin">
            <div className="tile__admin--imgwrapper">
              <img
                className="tile__admin--img"
                alt={iconAlt}
                src={`${process.env.staticUrl}/${icon}`}
              />
            </div>
            <div className="tile__admin--header">{header}</div>
            <div className="tile__admin--text">
              <span>{text}</span>
            </div>
          </button>
        </a>
      </Link>
      <style jsx>{styles}</style>
    </div>
  );
};

export default Tile;
