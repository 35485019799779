import { subDays } from "date-fns";
import React from "react";
import css from "styled-jsx/css";
import Page from "../components/page";
import Tile from "../components/tile";
import { usePermissions } from "../lib/hooks";
import { PageComponent } from "./_app";

const styles = css`
  .admin__wrapper--row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -9px;
    justify-content: center;
  }
`;

const HomePage: PageComponent = () => {
  const now = new Date();
  const oneWeekAgo = subDays(now, 7);

  const [hasPermission] = usePermissions();

  return (
    <Page>
      <div className="admin__wrapper">
        <div className="admin__wrapper--row">
          {hasPermission("viewOtherUsers") && (
            <Tile
              icon="icon-customers.svg"
              iconAlt="User icon"
              header="Customers"
              text="Find and edit Customer information"
              href="/users"
            />
          )}
          {hasPermission("viewOthersClaims") && (
            <Tile
              icon="icon-claims.svg"
              iconAlt="Clipboard"
              header="Claims"
              text="Claims handling dashboard"
              href="/claims"
            />
          )}

          <Tile
            icon="icon-admin-vet-list.svg"
            iconAlt="Vet list"
            header="Vets"
            text="Find and edit vet information"
            href="/vets"
          />
        </div>
        {/* <div className="admin__wrapper--row">
         <SparkTile
            sparkId="claimNewSpark"
            sparkHeight={70}
            sparkWidth={185}
            singularHeader="Claim made today"
            header="Claims made today"
            queryObject={{
              collection: "claim",
              type: "chart",
              from: oneWeekAgo.toISOString(),
              to: now.toISOString(),
              query: {
                type: "dateHistogram",
                interval: "daily",
                field: "event.when",
                values: { agg: "count" },
              },
              where: [],
            }}
          />

          <SparkTile
            sparkId="claimResolvedSpark"
            sparkHeight={70}
            sparkWidth={185}
            singularHeader="Claim resolved today"
            header="Claims resolved today"
            queryObject={{
              collection: "claim",
              type: "chart",
              from: oneWeekAgo.toISOString(),
              to: now.toISOString(),
              timeSpanField: "claim.endDate",
              query: {
                type: "dateHistogram",
                interval: "daily",
                field: "claim.endDate",
                values: { agg: "count" },
              },
              where: [{ field: "claim.status", value: "PROCESSED", op: "is" }],
            }}
          />

          <SparkTile
            sparkId="supportSpark"
            sparkHeight={70}
            sparkWidth={185}
            header="Support tickets generated today"
            singularHeader="Support ticket generated today"
            queryObject={{
              collection: "supportTicket",
              type: "chart",
              from: oneWeekAgo.toISOString(),
              to: now.toISOString(),
              query: {
                type: "dateHistogram",
                interval: "daily",
                field: "event.when",
                values: { agg: "count" },
              },
              where: [],
            }}
          />

          <SparkTile
            sparkId="quoteSpark"
            sparkHeight={70}
            sparkWidth={185}
            header="Quotes made today"
            singularHeader="Quote made today"
            queryObject={{
              collection: "policy",
              type: "chart",
              from: oneWeekAgo.toISOString(),
              to: now.toISOString(),
              query: {
                type: "dateHistogram",
                interval: "daily",
                field: "event.when",
                values: { agg: "count" },
              },
              where: [{ field: "policy.purchased", value: false, op: "is" }],
            }}
          />

          <SparkTile
            sparkId="newPolicySpark"
            sparkHeight={70}
            sparkWidth={185}
            header="New policies sold today"
            singularHeader="New policy sold today"
            queryObject={{
              collection: "policy",
              type: "chart",
              from: oneWeekAgo.toISOString(),
              to: now.toISOString(),
              timeSpanField: "policy.setup",
              query: {
                type: "dateHistogram",
                interval: "daily",
                field: "policy.setup",
                values: { agg: "count" },
              },
              where: [
                { field: "policy.purchased", value: true, op: "is" },
                { field: "policy.isRenewal", value: true, op: "is not" },
              ],
            }}
          />

          <SparkTile
            sparkId="renewedPolicySpark"
            sparkHeight={70}
            sparkWidth={185}
            header="Policies renewed today"
            singularHeader="Policy renewed today"
            queryObject={{
              collection: "policy",
              type: "chart",
              from: oneWeekAgo.toISOString(),
              to: now.toISOString(),
              timeSpanField: "policy.setup",
              query: {
                type: "dateHistogram",
                interval: "daily",
                field: "policy.setup",
                values: { agg: "count" },
              },
              where: [
                { field: "policy.purchased", value: true, op: "is" },
                { field: "policy.isRenewal", value: true, op: "is" },
              ],
            }}
          />

          <SparkTile
            sparkId="successfulPaymentSpark"
            sparkHeight={70}
            sparkWidth={185}
            header="Successful payments"
            singularHeader="Successful payment"
            queryObject={{
              collection: "payment",
              type: "chart",
              from: oneWeekAgo.toISOString(),
              to: now.toISOString(),
              timeSpanField: "payment.createdAt",
              query: {
                type: "dateHistogram",
                interval: "daily",
                field: "payment.createdAt",
                values: { agg: "count" },
              },
              where: [{ field: "payment.status", value: "success", op: "is" }],
            }}
          />

          <SparkTile
            sparkId="failedPaymentSpark"
            sparkHeight={70}
            sparkWidth={185}
            header="Failed payments"
            singularHeader="Failed payment"
            queryObject={{
              collection: "payment",
              type: "chart",
              from: oneWeekAgo.toISOString(),
              to: now.toISOString(),
              timeSpanField: "payment.createdAt",
              query: {
                type: "dateHistogram",
                interval: "daily",
                field: "payment.createdAt",
                values: { agg: "count" },
              },
              where: [
                { field: "payment.status", value: "success", op: "is not" },
              ],
            }}
          />

          <SparkTile
            sparkId="cancelledPolicySpark"
            sparkHeight={70}
            sparkWidth={185}
            header="Policies cancelled today"
            singularHeader="Policies cancelled today"
            queryObject={{
              collection: "policy",
              type: "chart",
              from: oneWeekAgo.toISOString(),
              to: now.toISOString(),
              timeSpanField: "policy.cancellationDate",
              query: {
                type: "dateHistogram",
                interval: "daily",
                field: "policy.cancellationDate",
                values: { agg: "count" },
              },
              where: [
                { field: "policy.purchased", value: true, op: "is" },
                { field: "policy.status", value: "cancelled", op: "is" },
              ],
            }}
          />
        </div> */}
        <div className="admin__wrapper--row">
          <Tile
            icon="icon-segments.svg"
            iconAlt="Pie chart"
            header="Segments"
            text="User segment groups for email campaigns"
            href="/segments"
          />
          {hasPermission("manageAffiliates") && (
            <Tile
              icon="icon-customers.svg"
              iconAlt="Affiliates"
              header="Affiliates & Campaign Manager"
              text="View and manage Affiliate campaigns"
              href="/affiliates"
            />
          )}
          {hasPermission("manageAffiliates") && (
            <Tile
              icon="icon-customers.svg"
              iconAlt="Affiliates"
              header="Affiliates Applications"
              text="Onboarding new affiliates"
              href="/affiliate-applications"
            />
          )}
          <Tile
            icon="icon-blog.svg"
            iconAlt="Pencil writing"
            header="CMS"
            text="Edit content in blog, wotw, etc..."
            href="/cms"
          />
          {hasPermission("manageFiles") && (
            <Tile
              icon="icon-admin-file-upload.svg"
              iconAlt="Upload"
              header="File Manager"
              text="Independent devs uploading their own pics."
              href="/uploader"
            />
          )}
        </div>
      </div>
      <style jsx>{styles}</style>
    </Page>
  );
};

HomePage.title = "Waggel - Admin";
HomePage.description = "Waggel admin";

export default HomePage;
